import Calculator from '../components/Calculator';
import './Calculator.css';

const Calc = () => (
  <section>
    <h2>Let&apos;s do some math!</h2>
    <Calculator />
  </section>
);
export default Calc;
